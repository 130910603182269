<template>
    <transition name="fade">
        <div class="theater" v-if="theaterOpen">
            <div class="backdrop" @click="closeTheater"></div>
            <video id="video" autoplay controls v-touch:swipe.up="closeTheater" v-touch:swipe.down="closeTheater"></video>
        </div>
    </transition>
</template>

<script>

export default {
  name: 'wod',
  props: { },

  computed: { },
  data: function(){
    return {
        theaterOpen: false,
    }
  },

  methods: {
        extractBaseUrlAndPath(url) {
            const urlObj = new URL(url);
            const baseUrl = urlObj.origin;
            const path = urlObj.pathname;

            return { baseUrl, path };
        },

        closeTheater(){
            var video = document.getElementById('video');
            video.pause()
            video.src = ''
            if(window.hls){
                window.hls.detachMedia();
                window.hls.destroy();
                this.$nextTick(() => { window.hls = null })
            }
            this.theaterOpen = false
        },

        wod(mode){
            const params = this.$parent.didParams

            this.$parent.didOpen = false

            if(mode === 'live'){
                this.theaterOpen = true
                this.$nextTick(() => {
                    var video = document.getElementById('video');
                    var videoSrc = params

                    if(typeof params === 'string' || params instanceof String){
                        videoSrc = params
                    }else{
                        videoSrc = params.link
                    }

                    videoSrc = videoSrc.replace('.ts', '.m3u8');

                    const { baseUrl, path } = this.extractBaseUrlAndPath(videoSrc);
                    videoSrc = `https://proxy.studio14a.xyz/iptv/${path}?base=${baseUrl}`

                    window.burl = baseUrl

                    if (window.Hls.isSupported()) {
                        window.hls = new window.Hls();
                        window.hls.loadSource(videoSrc);
                        window.hls.attachMedia(video);

                        window.hls.on(window.Hls.Events.ERROR, function(event, data) {
                            if (data.fatal) {
                                switch(data.type) {
                                    case window.Hls.ErrorTypes.NETWORK_ERROR:
                                        window.hls.loadSource(videoSrc);
                                        window.hls.attachMedia(video);

                                        break;
                                    case window.Hls.ErrorTypes.MEDIA_ERROR:
                                        break;
                                    default:
                                        break;
                                }
                            }
                        });

                    }
                })
            }

            if(mode.includes('vod')){
                const download = mode.includes('save')
                if(!download){ this.theaterOpen = true }
                this.$nextTick(async () => {
                    const response = await fetch(`${process.env.VUE_APP_SERVER}/mag`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ mag: params, episode: window.episode || 1 })
                    });
                    const debridURLData = await response.text();
                    // const p = `https://proxy.studio14a.com/file?url=${debridURLData}`;
                    if(download){
                        var a = document.createElement('a');
                        a.href = debridURLData;
                        if(a.href){
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }

                    }else{
                        var video = document.getElementById('video');
                        video.src = debridURLData
                    }

                })
            }

        },


  },

  mounted: function(){
    this.$root.$on('wod', (mode) => {
            this.wod(mode)
        })
  }
}
</script>

<style scoped lang="scss">
.theater{
    width: 90vw;
    height: 100vh;
    box-sizing: content-box;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5vw;
    video{
        width: 80vw;
        height: auto;
        position: relative;
        border-radius: 10px;
        z-index: 3;
    }
    .backdrop{
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 1;
        background-color: rgba(black, 0.95);
    }
}

</style>
